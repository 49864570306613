import React, { FC } from 'react'
import { Table as AntTable } from 'antd'

import './table.less'

export type TableProps = any & {
}

export const Table: FC<TableProps> = ({ className, ...rest }) => {
  return <AntTable className={`${className || ''} pyxis-table`} {...rest} />
}
