
import React, { FC } from 'react'
import { Avatar as AntAvatar } from 'antd'
import { AvatarProps as AntAvatarProps } from 'antd/lib/avatar'
import './avatar.less'

export type AvatarProps = AntAvatarProps & {
  // Custom Props definition
}

export const Avatar: FC<AvatarProps> = ({ className, ...rest }) => {
  return <AntAvatar className={`${className || ''} pyxis-avatar`} {...rest} />
}
