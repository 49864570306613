import React, { FC } from 'react'
import { Calendar as AntCalendar } from 'antd'

import './calendar.less'

export type CalendarProps = any & {
  // Custom Props definition
}

export const Calendar: FC<CalendarProps> = ({ className, ...rest }) => {
  return (
    <AntCalendar className={`${className || ''} pyxis-calendar`} {...rest} />
  )
}
