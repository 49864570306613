import React, { FC } from 'react'
import { PageHeader as AntPageHeader } from 'antd'
import { PageHeaderProps as AntPageHeaderProps } from 'antd/lib/page-header'

import './pageheader.less'

export type PageHeaderProps = AntPageHeaderProps & {
  // Custom Props definition
}

export const PageHeader: FC<PageHeaderProps> = ({ className, ...rest }) => {
  return (
    <AntPageHeader
      className={`${className || ''} pyxis-pageheader`}
      {...rest}
    />
  )
}
