
import React, { FC } from 'react'
import { Switch as AntSwitch } from 'antd'
import { SwitchProps as AntSwitchProps } from 'antd/lib/switch'

import './switch.less'

export type SwitchProps = AntSwitchProps & {
  // Custom Props definition
}

export const Switch: FC<SwitchProps> = ({ className, ...rest }) => {
  return <AntSwitch className={`${className || ''} pyxis-switch`} {...rest} />
}
