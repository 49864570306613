import React, { FC } from 'react'
import { Mentions as AntMentions } from 'antd'
import { MentionProps as AntMentionsProps } from 'antd/lib/mentions'
import './mentions.less'
const { Option } = AntMentions;

export type MentionsProps = AntMentionsProps & {
}

export const Mentions: FC<MentionsProps> = ({ className, ...rest }) => {
  return (
    <AntMentions className={`${className || ''} pyxis-mentions`} {...rest} />        
  )
}

export const MentionOption = Option;