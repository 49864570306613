
import React, { FC } from 'react'
import { Comment as AntComment} from 'antd';
import { CommentProps as AntCommentProps } from 'antd/lib/comment'
import './comment.less'

export type CommentProps = AntCommentProps & {
}

export const Comment: FC<CommentProps> = ({ className, ...rest }) => {
  return <AntComment 
  className={`${className || ''} pyxis-comment`} {...rest} />
}
