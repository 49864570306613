
import React, { FC } from 'react'
import { Cascader as AntCascader } from 'antd'
import { CascaderProps as AntCascaderProps } from 'antd/lib/cascader'

import './cascader.less'

export type CascaderProps = AntCascaderProps & {
  // Custom Props definition
}

export const Cascader: FC<CascaderProps> = ({ className, ...rest }) => {
  return (
    <AntCascader
      className={`${className || ''} pyxis-cascader`} 
      {...rest} 
    />
  )
}
