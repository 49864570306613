
import React, { FC } from 'react'
import { Tabs as AntTabs } from 'antd'
import { TabsProps as AntTabsProps } from 'antd/lib/tabs'

import './tabs.less'

export type TabsProps = AntTabsProps & {
  // Custom Props definition
}

export const Tabs: FC<TabsProps> = ({ className, ...rest }) => {
  return (
    <AntTabs 
      className={`${className || ''} pyxis-tabs`} 
      {...rest} 
    />
  )
}

export const TabPane = AntTabs.TabPane;