
import React, { FC } from 'react'
import { Spin as AntSpin } from 'antd'
import { SpinProps as AntSpinProps } from 'antd/lib/spin'

import './spin.less'

export type SpinProps = AntSpinProps & {
  // Custom Props definition
}

export const Spin: FC<SpinProps> = ({ className, ...rest }) => {
  return <AntSpin className={`${className || ''} pyxis-spin`} {...rest} />
}
