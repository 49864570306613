import React, { FC } from 'react'
import { Statistic as AntStatistic } from 'antd'

import './statistic.less'

export type StatisticProps = any & {
  // Custom Props definition
}

export const Statistic: FC<StatisticProps> = ({ className, ...rest }) => {
  return (
    <AntStatistic className={`${className || ''} pyxis-statistic`} {...rest} />
  )
}
