import React, { FC } from 'react'
import { AutoComplete as AntAutoComplete } from 'antd'
import { AutoCompleteProps as AntAutoCompleteProps } from 'antd/lib/auto-complete'

import './autocomplete.less'

export type AutoCompleteProps = AntAutoCompleteProps & {
  // Custom Props definition
}

export const AutoComplete: FC<AutoCompleteProps> = ({ className, ...rest }) => {
  return (
    <AntAutoComplete
      className={`${className || ''} pyxis-autocomplete`}
      {...rest}
    />
  )
}
