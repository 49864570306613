import React, { FC } from 'react'
import { Image as AntImage } from 'antd'

import './image.less'

export type ImageProps = any & {
}

export const Image: FC<ImageProps> = ({className,...rest}) => {
  return <AntImage className={`${className || ''} pyxis-image`} {...rest}/>
}
