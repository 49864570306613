
import React, { FC } from 'react'
import { Radio as AntRadio } from 'antd'
import { RadioProps as AntRadioProps } from 'antd/lib/radio'

import './radio.less'

export type RadioProps = AntRadioProps & {
  // Custom Props definition
}

export const Radio: FC<RadioProps> = ({ className, ...rest }) => {
  return <AntRadio className={`${className || ''} pyxis-radio`} {...rest} />
}
