
import React, { FC } from 'react'
import './draganddrop.less'
import { Upload as AntUpload} from 'antd'
import { UploadProps as AntUploadProps } from 'antd/lib/upload'
const { Dragger } = AntUpload;

export type DragAndDropProps = AntUploadProps & {
  // Custom Props definition
}

export const DragAndDrop: FC<DragAndDropProps> = ({ className, ...rest }) => {
  return ( 
    <Dragger
      className={`${className || ''} pyxis-upload`}
      {...rest}>
    </Dragger>
  )
}
