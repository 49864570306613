
import React, { FC } from 'react'
import { Space as AntSpace} from 'antd'
import { SpaceProps as AntSpaceProps } from 'antd/lib/space'
import './space.less'


export type SpaceProps = AntSpaceProps & {
  // Custom Props definition
}

export const Space: FC<SpaceProps> = ({ className, ...rest }) => {
  return (
    <AntSpace
      className={`${className || ''} pyxis-space`}
      {...rest}
    />
  )
}

