import React, { FC } from 'react'
import { Typography as AntTypography } from 'antd'
import { TypographyProps as AntTypographyProps } from 'antd/lib/typography/Typography'

import './typography.less'

export type TypographyProps = AntTypographyProps & {
  // Custom Props definition
}

export const Typography: FC<TypographyProps> = ({ className, ...rest }) => {
  return (
    <AntTypography
      className={`${className || ''} pyxis-typography`} 
      {...rest} 
    />
  )
}

export const Title = AntTypography.Title;
export const Text = AntTypography.Text;
export const Link = AntTypography.Link;