import React, { FC } from 'react'
import { List as AntList } from 'antd'
import { ListProps as AntListProps } from 'antd/lib/list'
import './list.less'

export type ListProps<T = {}> = AntListProps<T> & {
}

export const List: FC<ListProps> = ({ className, ...rest }) => {
  return <AntList
    className={`${className || ''} pyxis-list`}
    {...rest}
    />
}

export const ListItem = AntList.Item;
