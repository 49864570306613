
import React, { FC } from 'react'
import { Menu as AntMenu } from 'antd'
import { MenuProps as AntMenuProps } from 'antd/lib/menu'

import './menu.less'

export type MenuProps = AntMenuProps & {
  // Custom Props definition
}

export const Menu: FC<MenuProps> = ({ className, ...rest }) => {
  return <AntMenu className={`${className || ''} pyxis-menu`} {...rest} />
}

export const MenuItem = AntMenu.Item;
export const SubMenu = AntMenu.SubMenu;
export const MenuItemGroup = AntMenu.ItemGroup;