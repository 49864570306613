import React from 'react'
import { Select as AntSelect } from 'antd'
import { SelectProps as AntSelectProps } from 'antd/lib/select'
import './select.less'
const { Option } = AntSelect;

export type SelectProps = AntSelectProps<any> & {
  // Custom Props definition
}

export const Select = React.forwardRef<SelectProps, any> (({ className, ...rest }, ref) => {
  return (
    <AntSelect 
      className={`${className || ''} pyxis-select`} 
      {...rest}
      ref={ref} 
    />
  )
})

export const SelectOption = Option