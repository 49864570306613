
import React, { FC } from 'react'
import { Input as AntInput} from 'antd'
import { InputProps as AntInputProps } from 'antd/lib/input'
import './password.less'
import { EyeTwoTone,EyeInvisibleOutlined } from '@ant-design/icons';
const { Password } = AntInput;


export type PasswordInputProps = AntInputProps & {
  // Custom Props definition
}

export const InputPassword: FC<PasswordInputProps> = ({ className, ...rest }) => {
  return (
    <Password
      iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
      className={`${className || ''} pyxis-password`}
      {...rest}
    />
  )
}

