import React, { FC } from 'react'
import { TimePicker as AntTimePicker } from 'antd'
import { TimePickerProps as AntTimePickerProps } from 'antd/lib/time-picker'

import './timepicker.less'

export type TimePickerProps = AntTimePickerProps & {
  // Custom Props definition
}

export const TimePicker: FC<TimePickerProps> = ({ className, ...rest }) => {
  return (
    <AntTimePicker
      className={`${className || ''} pyxis-timepicker`}
      {...rest}
    />
  )
}

export const RangePicker =AntTimePicker.RangePicker 
