
import React, { FC } from 'react'
import { Drawer as AntDrawer } from 'antd'
import { DrawerProps as AntDrawerProps } from 'antd/lib/drawer'

import './drawer.less'

export type DrawerProps = AntDrawerProps & {
  // Custom Props definition
}

export const Drawer: FC<DrawerProps> = ({ className, ...rest }) => {
  return <AntDrawer className={`${className || ''} pyxis-drawer`} {...rest} />
}
