import React, { FC } from 'react'
import { Modal as AntModal } from 'antd'
import { ModalProps as AntModalProps } from 'antd/lib/modal'

import './modal.less'

export type ModalProps = AntModalProps & {
  // Custom Props definition
}

export const Modal: FC<ModalProps> = ({ className, ...rest }) => {
  return <AntModal className={`${className || ''} pyxis-modal`} {...rest} />
}
