import React from 'react'
import { Button as AntBtn } from 'antd'
import { ButtonProps as AntBtnProps } from 'antd/lib/button'
import './button.less'

export type ButtonProps = AntBtnProps & {
    // Custom Props definition
    btnsize ?:'sm' | 'md' | 'lg' | 'xl' 
}

export const Button= React.forwardRef<ButtonProps,any>(({ className,children,btnsize="sm", ...rest }, ref) => {
      return (
        <AntBtn
            className={`${className || ''} pyxis-btn btn-${btnsize}`} 
            {...rest} ref={ref}
        ><span className="btn-txt">{children}</span></AntBtn>
    )
})