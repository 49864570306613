import React, { FC } from 'react'
import { Transfer as AntTransfer } from 'antd'
import { TransferProps as AntTransferProps } from 'antd/lib/transfer'

import './transfer.less'

export type TransferProps<T = {}> = AntTransferProps<T> & {
  // Custom Props definition
}

export const Transfer: FC<TransferProps> = ({ className, ...rest }) => {
  return (
    <AntTransfer className={`${className || ''} pyxis-transfer`} {...rest} />
  )
}
