
import React, { FC } from 'react'
import { Tag as AntTag } from 'antd'
import { TagProps as AntTagProps } from 'antd/lib/tag'

import './tag.less'

export type TagProps = AntTagProps & {
  // Custom Props definition
}

export const Tag: FC<TagProps> = ({ className, ...rest }) => {
  return <AntTag className={`${className || ''} pyxis-tag`} {...rest} />
}
