
import React, { FC } from 'react'
import { Result as AntResult } from 'antd'
import { ResultProps as AntResultProps } from 'antd/lib/result'

import './result.less'

export type ResultProps = AntResultProps & {
  // Custom Props definition
}

export const Result: FC<ResultProps> = ({ className, ...rest }) => {
  return <AntResult className={`${className || ''} pyxis-result`} {...rest} />
}
