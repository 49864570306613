import React, { FC } from 'react'
import { DatePicker as AntDatePicker } from 'antd'
import { DatePickerProps as AntDatePickerProps } from 'antd/lib/date-picker'

import './datepicker.less'

export type DatePickerProps = AntDatePickerProps & {
  // Custom Props definition
}

export const DatePicker: FC<DatePickerProps> = ({ className, ...rest }) => {
  return (
    <AntDatePicker
      className={`${className || ''} pyxis-datepicker`}
      {...rest}
    />
  )
}
