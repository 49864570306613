
import React, { FC } from 'react'
import { Popconfirm as AntPopconfirm, message } from 'antd'
import { PopconfirmProps as AntPopconfirmProps } from 'antd/lib/popconfirm'

import './popconfirm.less'

export type PopconfirmProps = AntPopconfirmProps & {
  // Custom Props definition
}

export const Popconfirm: FC<PopconfirmProps> = ({ className, ...rest }) => {
  function confirm() {
    message.success('Click on Yes');
  }
  
  function cancel() {
    message.error('Click on No');
  }

  return (
    <AntPopconfirm
      className={`${className || ''} pyxis-popconfirm`} 
      {...rest} 
      onConfirm={confirm}
      onCancel={cancel}
    />
  )
}