import React, { FC } from 'react'
import { Dropdown as AntDropdown } from 'antd'
import { DropDownProps as AntDropdownProps } from 'antd/lib/dropdown'

import './dropdown.less'

export type DropdownProps = AntDropdownProps & {
  // Custom Props definition
}

export const Dropdown: FC<DropdownProps> = ({ className, ...rest }) => {
  return (
    <AntDropdown
      className={`${className || ''} pyxis-dropdown`} 
      {...rest} 
    />
  )
}

export const DropdownButton:any = AntDropdown.Button