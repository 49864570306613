import React from 'react'
import { TreeSelect as AntTreeSelect } from 'antd'
import { TreeSelectProps as AntTreeSelectProps } from 'antd/lib/tree-select'

import './treeselect.less'

export type TreeSelectProps<T = {}> = AntTreeSelectProps<T> & {
  // Custom Props definition
}

export const TreeSelect = React.forwardRef<TreeSelectProps, any>(({ className, ...rest }, ref) => {
  return (
    <AntTreeSelect
      className={`${className || ''} pyxis-treeselect`}
      {...rest} ref={ref}
    />
  )
})