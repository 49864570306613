import React, { FC } from 'react'
import { Button as AntBtn } from 'antd'
import { ButtonProps as AntBtnProps } from 'antd/lib/button'
import './socialbutton.less'
import {
  FacebookFilled,
  TwitterCircleFilled,
  InstagramFilled,
  LinkedinFilled,
  GoogleCircleFilled
} from '@ant-design/icons'

export type SocialButtonProps = AntBtnProps & {
  // Custom Props definition
  btnsize?: 'sm' | 'md' | 'lg'
  btntype?: 'facebook' | 'twitter' | 'instagram' | 'linkedin' | 'google'
}

export const SocialButton: FC<SocialButtonProps> = ({
  className,
  btnsize = 'sm',
  btntype = 'facebook',
  ...rest
}) => {
  let logo, content
  if (btntype === 'facebook') {
    logo = <FacebookFilled />
  } else if (btntype === 'twitter') {
    logo = <TwitterCircleFilled />
  } else if (btntype === 'instagram') {
    logo = <InstagramFilled />
  } else if (btntype === 'linkedin') {
    logo = <LinkedinFilled />
  } else if (btntype === 'google') {
    logo = <GoogleCircleFilled />
  }
  if (btnsize === 'md') {
    content = btntype.charAt(0).toUpperCase() + btntype.slice(1)
  } else if (btnsize === 'lg') {
    content =
      'Connect with ' + btntype.charAt(0).toUpperCase() + btntype.slice(1)
  }
  return (
    <AntBtn
      className={`${
        className || ''
      } pyxis-social-btn social-${btntype} social-${btnsize}`}
      {...rest}
    >
      <span>{logo}</span>
      <span>{content}</span>
    </AntBtn>
  )
}
