
import React, { FC } from 'react'
import { Skeleton as AntSkeleton } from 'antd'
import { SkeletonProps as AntSkeletonProps } from 'antd/lib/skeleton'

import './skeleton.less'

export type SkeletonProps = AntSkeletonProps & {
  // Custom Props definition
}

export const Skeleton: FC<SkeletonProps> = ({ className, ...rest }) => {
  return <AntSkeleton className={`${className || ''} pyxis-skeleton`} {...rest} />
}
