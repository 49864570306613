
import React, { FC } from 'react'
import { Empty as AntEmpty } from 'antd'
import { EmptyProps as AntEmptyProps } from 'antd/lib/empty'

import './empty.less'

export type EmptyProps = AntEmptyProps & {
  // Custom Props definition
}

export const Empty: FC<EmptyProps> = ({ className, ...rest }) => {
  return <AntEmpty className={`${className || ''} pyxis-empty`} {...rest} />
}
