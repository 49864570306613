import React, { FC } from 'react'
import { Alert as AntAlert } from 'antd'
import { AlertProps as AntAlertProps } from 'antd/lib/alert'

import './alert.less'

export type AlertProps = AntAlertProps & {
    // Custom Props definition
}
  
export const Alert: FC<AlertProps> = ({ className, ...rest }) => {
    return (
        <AntAlert
            className={`${className || ''} pyxis-alert`} 
            {...rest} 
        />
    )
}
