import React, { FC } from 'react'
import { Col as AntCol } from 'antd'
import { ColProps as AntColProps } from 'antd/lib/grid/col'

import './col.less'

export type ColProps = AntColProps & {
  // Custom Props definition
}

export const Col: FC<ColProps> = ({ className, ...rest }) => {
  return (
    <AntCol
      className={`${className || ''} pyxis-col`} 
      {...rest} 
    />
  )
}
