
import React, { FC } from 'react'
import { Upload as AntUpload} from 'antd'
import { UploadProps as AntUploadProps } from 'antd/lib/upload'
import './upload.less'
const { Dragger } = AntUpload;

export type UploadProps = AntUploadProps & {
  // Custom Props definition
  uploadButton:any
}

export const Upload: FC<UploadProps> = ({ className,uploadButton, ...rest }) => {
  return (
    <AntUpload
      className={`${className || ''} pyxis-upload`}
      {...rest}>
        {uploadButton}
    </AntUpload>
  )
}

export const UploadDragger = Dragger;