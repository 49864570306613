
import React, { FC } from 'react'
import { Tooltip as AntTooltip } from 'antd'
import { TooltipProps as AntTooltipProps } from 'antd/lib/tooltip'

import './tooltip.less'

export type TooltipProps = AntTooltipProps & {
  // Custom Props definition
  content:any
}

export const Tooltip: FC<TooltipProps> = ({ className,content, ...rest }) => {
  return  (
  <AntTooltip className={`${className || ''} pyxis-tooltip`} {...rest} >
    {content}
  </AntTooltip>
  )
}
